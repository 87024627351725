<template>
    <div>
        <v-dialog v-model="$store.state.dialog" width="50%" persistent="">
            <v-card flat>
                <v-toolbar color="primary" dark>
                    <v-toolbar-title>
                        {{
                            $store.state.bodyDetails.dialogType == "add"
                                ? "اضافة"
                                : "تعديل"
                        }}
                        معرف جديد
                    </v-toolbar-title>
                    <v-spacer />
                    <v-btn @click="close()" color="error">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-col>
                    <v-form
                        @submit.prevent="submit()"
                        ref="form"
                        v-model="valid"
                        lazy-validation>

                        <v-row no-gutters class="mt-4">
                            <v-col cols="12" md="8" class="pl-2">
                                <small class="font-weight-bold">
                                    الاسم الكامل للمعرف
                                    <span class="red--text font-weight-bold"
                                        >*</span
                                    >
                                </small>
                                <v-text-field
                                    v-model="body.identifierName"
                                    :rules="[required()]"
                                    required
                                    outlined
                                    dense
                                    color="secondary"
                                    filled
                                    placeholder="الاسم الكامل للمعرف"
                                >
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" md="6" class="pl-2">
                                <small class="font-weight-bold">
                                    معرف التليكرام
                                </small>
                                <v-text-field
                                    v-model="body.telegramId"
                                    :rules="[required()]"
                                    required
                                    outlined
                                    dense
                                    color="secondary"
                                    filled
                                    placeholder=" @abc123.. معرف التليكرام "
                                >
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" md="6" class="pl-2">
                                <small class="font-weight-bold">
                                    رقم الموبايل
                                </small>

                                <VuePhoneNumberInput
                                    filled
                                    phoneNumberLabel="078X XXX XXXX"
                                    no-example
                                    class="rtl"
                                    default-country-code="IQ"
                                    v-model="body.phoneNo"
                                />
                            </v-col>

                            <v-col cols="12" md="12">
                                <small>
                                    اكتب وصفاً لةذا الموظف
                                    <span class="grey--text"
                                        >اي ملاحضات في خصوص ةذا الموظف</span
                                    >
                                </small>
                                <v-textarea
                                    class="mt-1"
                                    v-model="body.notes"
                                    required
                                    outlined
                                    filled
                                    label="لا توجد ملاحضات"
                                    color="secondary"
                                >
                                </v-textarea>
                            </v-col>

                            <v-col cols="12" md="12" class="mt-2">
                                <v-btn
                                    type="submit"
                                    :disabled="!body.identifierName"
                                    :loading="loading"
                                    x-large
                                    color="primary"
                                    >حفظ</v-btn
                                >
                                <v-btn
                                    @click="close()"
                                    x-large
                                    color="error"
                                    class="mr-2"
                                    outlined
                                >
                                    إلغاء
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import validations from "@/utils/validations";
import { mapState } from "vuex";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
    data: () => ({
        valid: true,
        body: {},
        loading: false,
        ...validations
    }),

    components: {
        VuePhoneNumberInput
    },

    computed: {
        ...mapState(["bodyDetails"])
    },

    created() {
        this.body = {};
    },

    mounted() {
        this.$eventBus.$on("fill-fields", () => {
            this.body = this.bodyDetails;
        });
    },

    methods: {
        submit() {
            if (this.bodyDetails.dialogType == "add") {
                this.addItem();
            } else {
                this.editItem();
            }
        },
        addItem() {
            this.loading = true;
            this.$GenericService
                .create("Identifier", this.body)
                .then(res => {
                    this.close();
                    this.$store.dispatch("setSnackbar", {
                        text: `تم الإضافة بنجاح`
                    });
                    this.$eventBus.$emit(this.$route.path);
                })
                .catch(err => {
                    this.$store.dispatch("setSnackbar", {
                        text: ` ${err.response.data.message}`,
                        color: "error"
                    });
                })
                .finally(() => (this.loading = false));
        },

        editItem() {
            this.loading = true;
            const data = Object.entries(this.body).reduce(
                (a, [k, v]) => (v ? ((a[k] = v), a) : a),
                {}
            ); //remove null fields

            this.$GenericService
                .update(`Identifier/edit/${this.body.id}`, data)
                .then(res => {
                    this.close();
                    this.$store.dispatch("setSnackbar", {
                        text: `تم التعديل بنجاح`
                    });
                    this.$eventBus.$emit(this.$route.path);
                })
                .catch(err => {
                    this.$store.dispatch("setSnackbar", {
                        text: ` ${err.response.data.message}`,
                        color: "error"
                    });
                })
                .finally(() => (this.loading = false));
        },

        close() {
            this.$store.dispatch("setDialog");
            this.body = {};
            this.resetValidation();
            this.$store.dispatch("setBodyDetails", {});
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        }
    }
};
</script>

<style>
.rtl {
    direction: ltr !important;
}
</style>
